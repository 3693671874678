<script>
import { certificates } from "@/config/api/certificates";
import { getFormData } from "@/utils/createForm";

export default {
  mounted() {},
  props: ["selectedCertificateType", "allCertificates", "currentCertificate"],
  data() {
    return {
      values: {},
      image: null,
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 2,
          type: "text",
          name: "description",
          label: "Description",
          value: "",
        },
        {
          index: 3,
          type: "checkbox",
          name: "by_default",
          label: "By default",
        },
        {
          index: 4,
          type: "checkbox",
          name: "optional",
          label: "Optional",
        },
      ],
    };
  },
  methods: {
    resetModal() {
      this.image = null;
      this.$formulate.reset("CertificateTypeAction", {});
      this.$emit("resetModal");
    },
    async submitHandler(data) {
      if (this.selectedCertificateType) {
        if (data.Image) {
          data.file = data.Image[0].location;
          data.image = data.Image[0].location;
        }
        this.updateCertificateType(data, this.selectedCertificateType._id);
      } else {
        const formdata = getFormData({
          file: this.image.files[0].file,
          name: data.name,
          description: data.description,
          certificate_id: this.currentCertificate._id,
          optional:data.optional,
          by_default:data.by_default

        });
        console.log(formdata);

        this.addCertificateType(formdata);
      }
    },
    addCertificateType(data) {
      this.performAction = true;
      const api = certificates.certificateTypes.create;
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Certificate Type added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Certificate Type is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateCertificateType(data, id) {
      this.performAction = true;
      const api = certificates.certificateTypes.update;
      api.data = data;
      api.id = id;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Certificate Type updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Certificate Type is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedCertificateType(val) {
      if (val) {
        this.values = { ...val };
      }
    },
  },
};
</script>

<template>
  <b-modal
    :id="'action-CertificateType_' + currentCertificate._id"
    @hide="resetModal"
    :title="
      selectedCertificateType
        ? `Update Certificate Type - ${currentCertificate.name}`
        : `Add Certificate Type - ${currentCertificate.name}`
    "
    hide-footer
    size="lg"
  >
    <FormulateForm
      name="CertificateTypeAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput
        type="image"
        name="Image"
        v-model="image"
        label="Select an image to upload"
        help="Select a png or jpg to upload."
        :validation="
          selectedCertificateType
            ? 'mime:image/jpeg,image/png,image/webp'
            : 'required:mime:image/jpeg,image/png,image/webp'
        "
      />
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedCertificateType"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedCertificateType"> Update </span>
          <span v-else> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>


<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>