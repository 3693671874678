<script>
import appConfig from "@/app.config";
import { certificates } from "@/config/api/certificates";
import CertificateTypeModal from "@/components/certificates/certificate-type-modal";
/**
 * Advanced table component
 */
export default {
  page: {
    title: "CERTIFICATES",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { CertificateTypeModal },
  props: ["currentCertificate", "certificatesData"],
  data() {
    return {
      certificateTypeData: [],
      title: "Certificate Types",
      selectedCertificateType: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "certificateType", sortable: true },
        { key: "image", sortable: false },
        { key: "certificate", sortable: true },
        { key: "createdAt", sortable: true },
        { key: "updatedAt", sortable: true },
        { key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    certificates() {
      let certificates = [];
      this.certificatesData.forEach((element) => {
        certificates.push({
          _id: element._id,
          name: element.name,
        });
      });
      return certificates;
    },
    rows() {
      return this.certificateTypeData.length;
    },
    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
    deletedCertificateTypes() {
      return this.certificateTypeData.filter(
        (email) => email.deletedAt != null
      );
    },
    activeCertificateTypes() {
      return this.certificateTypeData.filter(
        (email) => email.deletedAt === null
      );
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.certificateTypeData.length;
 
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isBusy = true;
      const api = certificates.certificateTypes.get;
      await this.generateAPI(api)
        .then((res) => {
          this.certificateTypeData = res.data.certificates_types.filter(
            (typesBy) => typesBy.certificate === this.currentCertificate._id
          );
          this.isBusy = false;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editCertificateType(data) {
      this.selectedCertificateType = data;
      this.showActionModal();
    },
    deleteCertificateType(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = certificates.certificateTypes.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Certificate Type deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadData();
              })
              .catch((err) => {
                this.$bvToast.toast("Certificate Type is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.log(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    showActionModal() {
      this.$bvModal.show(
        "action-CertificateType_" + this.currentCertificate._id
      );
    },
    hideActionModal() {
      this.selectedCertificateType = null;
      this.$bvModal.hide(
        "action-CertificateType_" + this.currentCertificate._id
      );
    },
 
  },
};
</script>

<template>
  <div class="row">
    <!-- Table -->
    <div class="card-body pt-0">
      <b-button
        id="addCertificateTypes"
        @click="showActionModal"
        pill
        variant="primary"
        >Add Certificate Types</b-button
      >
      <!-- All Certificates -->
      <b-tabs
        nav-class="nav-tabs-custom"
        v-model="activeTab"
        active-nav-item-class="font-weight-bold"
        active-tab-class="font-weight-bold"
        content-class="mt-3"
      >
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">All Certificate Types</a>
          </template>
          <div class="table-responsive">
            <b-table
              v-if="activeTab === 0"
              bordered
              striped
              :items="certificateTypeData"
              :fields="fields"
              responsive="sm"
              :busy="isBusy"
              show-empty
            >
              <template #table-busy>
                <div style="text-align: center" class="mt-5 mb-5">
                  <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                </div>
              </template>
              <template #cell(image)="row">
                <a :href="row.item.image" target="_blank">
                  <img
                    :src="row.item.image"
                    style="
                      height: 50px;
                      width: 50px;
                      object-fit: cover;
                      cursor: pointer;
                    "
                /></a>
              </template>
              <template #cell(actions)="row">
                <button
                  class="action-btn"
                  @click="deleteCertificateType(row.item)"
                >
                  <i class="ri-delete-bin-line"></i>
                </button>
                <button
                  class="action-btn"
                  @click="editCertificateType(row.item)"
                >
                  <i class="ri-edit-line"></i>
                </button>
              </template>
              <template #cell(certificateType)="row">
                {{ row.item.name }}
                <br />
                <b-badge pill variant="danger" v-if="row.item.deletedAt"
                  >Deleted</b-badge
                >
              </template>
              <template #cell(certificate)>
                {{ currentCertificate.name }}
              </template>
              <template #cell(createdAt)="row">
                {{ moment(row.item.createdAt).format("l") }}
              </template>
              <template #cell(updatedAt)="row">
                {{ moment(row.item.updatedAt).format("l") }}
              </template>
            </b-table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <CertificateTypeModal
      :currentCertificate="currentCertificate"
      :allCertificates="certificates"
      :selectedCertificateType="selectedCertificateType"
      v-on:resetModal="selectedCertificateType = null"
      v-on:reloadData="loadData"
      v-on:closeModal="hideActionModal"
    />
  </div>
</template>

<style scoped>
#addCertificateTypes {
  float: right;
  margin-top: 10px;
}
.row {
  background-color: #f1f5f7;
}
</style>